<template>
  <div class="cell-wrap">
    <div class="cell-title">{{ title || "标题" }}</div>
    <div v-if="value" class="cell-value">{{ value }}</div>
    <div v-else class="cell-value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyCell',
  props:{
    title: {
      type: String,
      reqiure: true,
    },
    value: {
      reqiure: true,
    },
  }
}
</script>

<style lang="scss">
.cell-wrap{
  padding: 6px 0;
  display: flex;
  justify-content: space-between;
  .cell-title{
    flex: 1;
    color: $gray;
  }
  .cell-value{
    flex: 1;
    color: $font;
    text-align: right;
  }
}
</style>